// Library
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

// MUI
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'

// CONST
import { CONST } from '../../../../const/const'

// Component
import Accordion from '../../../accordion/common/Accordion'
import ExhibitorOrderOfGoAccordion from './accordions/ExhibitorOrderOfGoAccordion'
import ViewsLoader from '../../../loader/ViewsLoader'
import { RedTitle } from './global/RedTitle'
import { DaysTabs } from '../../common/DaysTabs/DaysTabs'

// FireBase
import FirestoreService from '../../../../services/firestoreService'
import { where } from 'firebase/firestore'

// Helper
import { getConvertedData } from '../../../../models/interface.helper'

// Models
import { RegistrationByDayModel } from '../../../../models/registrations-by-day/registrationByDay.model'

import { sortByOrder } from '../../../../pages/organizer/manage/clinic/ManageClinicNOtherOperationsTab/ManageClinicNOtherOperationsTab'
import useHorse from '../../../../pages/organizer/manage/common/table/HorsesManage/hooks/useHorse'
import { IRegistrationByDayInterface } from '../../../../models/registrations-by-day/registrationByDay.interface'
import { EventFeesModel } from '../../../../models/event-fees/event-fees.model'
import { daysOfWeek } from '../../../../helpers/time'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const EventOrderOfGo = () => {
  // Hooks and vars
  const eventId = useParams<{ eventId: string }>().eventId ?? null
  const { horses } = useHorse(eventId)

  const [classes, setClasses] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [classOption, setClassOptions] = useState<any[]>([])
  const [days, setDays] = useState<string[]>([])
  const [activeDay, setActiveDay] = useState<string>('All')

  // Functions
  const handleGetFeesData = async () => {
    try {
      const snapShot = await FirestoreService.getItem(COLLECTIONS.EVENT_FEES.NAME, eventId)

      const registrationFees = new EventFeesModel(snapShot.data()).toFirestore()
      const days_: string[] = []

      const tempOptions = Object.values(
        [...registrationFees?.registrationFees].reduce((acc, item) => {
          const day = daysOfWeek[new Date(item.startDate)?.getDay()]
          if (!days_.includes(day)) days_.push(day)

          acc[item.uuid] = {
            value: item.uuid,
            label: item.name,
            actualCostAlias: item.actualCostAlias,
            dayOfWeek: day,
            fullTime: `${item.startTimeHours}:${item.startTimeMinutes} ${item.startTimeFormat}`,
            increment: item.increment,
            publishOrder: item.publishOrder,
            location: item.location,
          }
          return acc
        }, {})
      )

      setDays(
        days_.sort((a, b) => {
          return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b)
        })
      )
      setClassOptions(tempOptions)
    } catch (error) {
      console.error(error)
    }
  }

  // Fetching Riders and Horses from REGISTRATION_BY_DAY Collections (Depends on Class)
  const handleGetOrderData = async () => {
    setLoading(true)
    let filterClasses: any[] = []

    try {
      let queries: any = [where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.EVENT_ID.KEY, '==', eventId)]

      const snapShot = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        queries
      )

      if (snapShot.size) {
        snapShot.docs.forEach((currDoc) => {
          filterClasses.push(
            getConvertedData(RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject())
          )
        })
      }

      const combinedArray = classOption.map((item1: any) => {
        const correspondingItem = filterClasses.filter((item2: any) => item1.value === item2.uuid)

        const uniqueData = correspondingItem.reduce(
          (acc: IRegistrationByDayInterface[], currentItem) => {
            // Check if the riderId of the current item is already in the accumulator
            const isRiderIdExist = acc.some((item) => item.riderId === currentItem.riderId)
            // If riderId doesn't exist, add the current item to the accumulator
            if (!isRiderIdExist) {
              const newItem = {
                ...currentItem,
              }
              acc.push(newItem)
            }
            return acc
          },
          []
        )

        return {
          ...item1,
          registered: uniqueData,
        }
      })

      setClasses(combinedArray)

      setTimeout(() => {
        setLoading(false)
      }, 1000)
    } catch (error) {
      setLoading(false)
      console.error(error, 'error')
    }
  }

  useEffect(() => {
    handleGetFeesData().then()
  }, [])

  useEffect(() => {
    handleGetOrderData().then()
  }, [classOption])

  useEffect(() => {
    console.log(loading, 'loading')
  }, [loading])

  return (
    <>
      <RedTitle text="Classes" className="mb-4 sm:mb-7" />

      {loading ? (
        <div className="flex justify-center items-center h-[100px]">
          <ViewsLoader
            className="flex items-center w-full justify-center min-h-[30vh] mt-3"
            size="lg"
            color="#F7074F"
          />
        </div>
      ) : classes.length > 0 ? (
        <>
          <DaysTabs activeDay={activeDay} setActiveDay={setActiveDay} days={days} />
          {sortByOrder(classes).map((item: any, index: number) => {
            let registered = 0
            if (item.registered && Array.isArray(item.registered))
              registered = item.registered.length

            if (item.dayOfWeek === activeDay || activeDay === 'All') {
              return (
                <Accordion
                  key={index}
                  icon={<AddCircleOutline className="add_icon text-[red]" />}
                  initialState={false}
                  status={!item.publishOrder ? 'Pending' : 'Available'}
                  className="mb-2"
                  header={
                    <div className="flex flex-col md:flex-row gap-2 text-[14px] justify-between pr-[10px]">
                      {item.label}{' '}
                      <div className={'text-[#122B4680] font-normal'}>
                        <span className={'customValue text-[#122B46]'}>
                          {item.actualCostAlias || '$0'}
                        </span>{' '}
                        entry fee •{' '}
                        <span className={'customValue text-[#122B46]'}>{registered}</span>{' '}
                        registered •{' '}
                        <span className={'customValue text-[#122B46]'}>{item.fullTime}</span>,{' '}
                        {item.dayOfWeek} • <span className="text-[#122B46]">{item.location}</span>
                      </div>
                    </div>
                  }
                  headerTextClassName="text-SeabiscuitDark200ThemeColor capitalize w-full pr-2"
                  activeIcon={<RemoveCircleOutline className="add_icon text-[red]" />}
                >
                  <>
                    <ExhibitorOrderOfGoAccordion {...item} horses={horses} />
                  </>
                </Accordion>
              )
            } else {
              return null
            }
          })}
        </>
      ) : (
        <p className="text-SeabiscuitDark200ThemeColor/50">Classes not found</p>
      )}
    </>
  )
}

export default EventOrderOfGo
