import React, { FC, useState } from 'react'

import Clock from 'web/public/assets/cp_icons/Clock-1.svg'

import { getIconColor } from '../../../../../helpers/getIconColor'
import { IRegistrationByDayInterface } from '../../../../../models/registrations-by-day/registrationByDay.interface'
import { sortByOrder } from '../../../../../pages/organizer/manage/clinic/ManageClinicNOtherOperationsTab/ManageClinicNOtherOperationsTab'
import { IMAGE_CONSTS } from '../../../../../const/image-const'
import { IHorseData } from '../../../../../models/horse/horse.interface'
import { convertTime } from '../../../../../helpers/time'

// Types
type Data = {
  data: IRegistrationByDayInterface[]
  fullTime: string
  increment: string
  publishOrder: boolean
  horses: IHorseData[]
}

interface DetailRowProps {
  fullTime: string
  increment: string
  index: number
  color: string
  row: IRegistrationByDayInterface
  publishOrder: boolean
  horse?: IHorseData
}
const DetailRow: FC<DetailRowProps> = ({
  row,
  fullTime,
  increment,
  publishOrder,
  index,
  color,
  horse,
}) => {
  const [errorImg, setErrorImg] = useState(false)
  const newIncrement = increment && increment.length === 8 ? increment : '00:00:00'
  const arrayName = row.riderName?.split(' ') || []

  return (
    <>
      <div className="flex flex-wrap items-center justify-between w-full my-2 gap-4 md:gap-2">
        <div className="w-full md:w-1/3 flex items-center">
          {row.riderProfilePicture && !errorImg ? (
            <img
              onError={() => setErrorImg(true)}
              src={row.riderProfilePicture}
              alt="avatar"
              className="w-[45px] h-[45px] rounded-full mt-1"
            />
          ) : (
            <div
              style={{ background: color }}
              className={`w-[45px] h-[45px] rounded-full mt-1 flex items-center justify-center text-white`}
            >
              {arrayName[0][0]}
              {arrayName.length > 1 ? arrayName[arrayName.length - 1][0] : ''}
            </div>
          )}
          <div className="ml-3">
            <div className="text-SeabiscuitDark200ThemeColor font-bold text-base">
              {row.riderName}
            </div>
            <span className="text-base text-SeabiscuitDark200ThemeColor/50">
              {row.backNumber ? `Back number • ${row.backNumber}` : null}
            </span>
          </div>
        </div>
        <div className="w-full md:w-1/3 flex items-center">
          <img
            src={
              row?.horseProfilePicture ? row.horseProfilePicture : IMAGE_CONSTS.PLACEHOLDERS.USER
            }
            alt="avatar"
            className="w-[45px] h-[45px] rounded-full mt-1"
          />
          <div className="ml-3">
            <div className="text-SeabiscuitDark200ThemeColor font-bold text-base">
              {row.horseName ?? 'No Horse'}
            </div>
            <span className="text-base text-SeabiscuitDark200ThemeColor/50">
              {horse?.trainer ? `Trainer • ${horse?.trainer}` : null}
            </span>
          </div>
        </div>
        {publishOrder ? (
          row.orderOfGoScratched ? (
            <div className="md:ml-auto">
              <div className="md:text-right text-SeabiscuitDark200ThemeColor">No Place</div>
              <div className="md:text-right text-[14px] text-SeabiscuitDark200ThemeColor/50">
                Scratched
              </div>
            </div>
          ) : (
            <div className="md:ml-auto flex gap-3 justify-center items-center">
              <div>
                <div className="font-semibold text-right">Spot</div>
                <div className="opacity-50 flex items-center gap-1 text-[14px]">
                  <img src={Clock} className="w-4" alt="" />
                  {convertTime(fullTime, newIncrement, index)}
                </div>
              </div>
              <div className="p-1 rounded-full w-[45px] h-[45px] flex items-center justify-center bg-gray-200 text-black">
                {row.order}
              </div>
            </div>
          )
        ) : (
          <div className="md:ml-auto">
            <div className="md:text-right text-SeabiscuitDark200ThemeColor">Order of Go</div>
            <div className="md:text-right text-[14px] text-SeabiscuitDark200ThemeColor/50">
              Not yet published
            </div>
          </div>
        )}
      </div>
      <hr></hr>
    </>
  )
}

const ExhibitorOrderOfGoAccordion: FC<Data> = ({
  data,
  fullTime,
  increment,
  publishOrder,
  horses,
}) => {
  return (
    <>
      {data?.length > 0 &&
        sortByOrder(data).map((currentItem: IRegistrationByDayInterface, index: number) => {
          return (
            <DetailRow
              key={`${JSON.stringify(currentItem)}${index}`}
              row={currentItem}
              fullTime={fullTime}
              increment={increment}
              publishOrder={publishOrder}
              horse={horses.find((horse) => horse.id === currentItem.horseId)}
              index={index}
              color={getIconColor(data?.length, index)}
            />
          )
        })}
    </>
  )
}

export default ExhibitorOrderOfGoAccordion
